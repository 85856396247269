import { createGlobalStyle } from 'styled-components';
import { BACKGROUND } from './constants/images';
import { device } from './constants/size';
import theme from './constants/theme';

export const GlobalStyle = createGlobalStyle`
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
    padding: 0;
  }

  * {
    font-family: 'Montserrat', sans-serif;
  }

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background: right bottom no-repeat url(${BACKGROUND}) ${theme.color.grayBackground};
    background-size: 65vw auto;
    cursor: default;

    @media (max-height: 900px) {
      background: ${theme.color.grayBackground};
    }

    @media (max-width: 1500px) {
      background: ${theme.color.grayBackground}
    }
  }
  
`;
