const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	dekstopS: '1600px',
	desktop: '1767px',
	desktopL: '1920px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(max-width: ${size.laptopL})`,
	dekstopS: `(max-width: ${size.dekstopS})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopL: `(max-width: ${size.desktopL})`,
};
