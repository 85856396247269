import React from 'react';
import { Wrapper } from './style';

const Container = (props) => {
	const { children } = props;

	return <Wrapper>{children}</Wrapper>;
};

export default Container;
