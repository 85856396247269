import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Burger from '../../components/Burger/burger';
import { Wrapper } from '../../constants/wrappers';
import { PARTNERS1 } from '../../constants/images';
import { PARTNERS2 } from '../../constants/images';
import { PARTNERS3 } from '../../constants/images';
import { PARTNERS4 } from '../../constants/images';
import { PARTNERS5 } from '../../constants/images';
import { PARTNERS6 } from '../../constants/images';

import {
	ContantWrapper,
	PartnersWrapper,
	Item,
	Title,
	SubTitle,
	LableWrapper,
	Lable,
	Image,
} from './style';

export const Partners = () => {
	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<ContantWrapper>
					<Title>Наши партнеры</Title>
					<PartnersWrapper>
						<Item>
							<SubTitle>DKS</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>кабеленесущих</Lable>
								<Lable>систем</Lable>
							</LableWrapper>
							<Image src={PARTNERS1} />
						</Item>
						<Item>
							<SubTitle>КВТ</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>электротехнической</Lable>
								<Lable>продукции</Lable>
							</LableWrapper>
							<Image src={PARTNERS2}/>
						</Item>
						<Item>
							<SubTitle>Световые технологии</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>светотехнической</Lable>
								<Lable>продукции</Lable>
							</LableWrapper>
							<Image src={PARTNERS3}/>
						</Item>
						<Item>
							<SubTitle>IEK</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>электротехнического</Lable>
								<Lable>оборудования</Lable>
							</LableWrapper>
							<Image src={PARTNERS4} />
						</Item>
						<Item>
							<SubTitle>КОНКОРД</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>кабельных</Lable>
								<Lable>изделий</Lable>
							</LableWrapper>
							<Image src={PARTNERS5} />
						</Item>
						<Item>
							<SubTitle>КАМКАБЕЛЬ</SubTitle>
							<LableWrapper>
								<Lable>Производитель</Lable>
								<Lable>кабельной</Lable>
								<Lable>инфаструктуры</Lable>
							</LableWrapper>
							<Image src={PARTNERS6}/>
						</Item>
					</PartnersWrapper>
				</ContantWrapper>
				<Footer />
			</Container>
		</Wrapper>
	);
};
