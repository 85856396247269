import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonForm from '../../components/ButtonForm';
import ButtonNext from '../../components/ButtonNext';
import Burger from '../../components/Burger/burger';
import { IMG_HOME } from '../../constants/images';
import { Wrapper } from '../../constants/wrappers';
import { AppRoutes } from '../../constants';

import {
	Title,
	SubTitle,
	Lable,
	SubLable,
	FlexText,
	FlexBtn,
	Image,
	ImageWrapper,
} from './style';

export const Home = (props) => {
	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<Image />
				<FlexText>
					<Title>КАБЕЛЬ ПРОВОД КРУПНОГО СЕЧЕНИЯ</Title>
					<Lable>Актуальные цены на ассортимент</Lable>
					<SubLable>ООО ГК ЭНЕРГОСИЛА</SubLable>
				</FlexText>
				<FlexBtn>
					<ButtonForm />
					<ButtonNext path={AppRoutes.Catalog} text='Перейти к каталогу' />
				</FlexBtn>
				<Footer />
			</Container>
			<ImageWrapper>
				<Image src={IMG_HOME} />
			</ImageWrapper>
		</Wrapper>
	);
};


// export const Home = (props) => {
// 	return (
// 		<Wrapper>
// 			<Burger />
// 			<Container>
// 				<Header />
// 				<Image />
// 				<FlexText>
// 					<Title>РАССЕКИ СВОИ ГРАНИЦЫ</Title>
// 					<SubTitle>КАБЕЛЬ ПРОВОД КРУПНОГО СЕЧЕНИЯ</SubTitle>
// 					<Lable>Актуальные цены на ассортимент</Lable>
// 					<Lable>Стальная гарантия качества</Lable>
// 					<SubLable>ООО ГК ЭНЕРГОСИЛА</SubLable>
// 				</FlexText>
// 				<FlexBtn>
// 					<ButtonForm />
// 					<ButtonNext path={AppRoutes.Catalog} text='Перейти к каталогу' />
// 				</FlexBtn>
// 				<Footer />
// 			</Container>
// 			<ImageWrapper>
// 				<Image src={IMG_HOME} />
// 			</ImageWrapper>
// 		</Wrapper>
// 	);
// };