import React, {useEffect} from 'react';
import Container from '../../components/Container';
import { copy } from '../../constants/copy';
import { FormWrapperMain } from '../../constants/wrappers';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { LOGO } from '../../constants/images';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { useMemo } from 'react';
import { send } from 'emailjs-com';
import Burger from '../../components/Burger/burger';


import {
	ContainerWrapper,
	Title,
	DecsWrapper,
	Line,
	DescTitle,
	ContactLink,
	DecsAdress,
	DecsPhones,
	DecsMail,
	Lable,
	FormWrapper,
	FormItem,
	FormField,
	Button,
	Loader,
	FormResult,
	//
	HeaderWrapper,
	StyledLink,
	NavBar,
	NavItem,
	Logo,
	ContactLinkAdress,
} from './style';


const links = [
	{
		id: 1,
		label: 'Главная',
		path: AppRoutes.Home,
	},
	{
		id: 2,
		label: 'Каталог',
		path: AppRoutes.Catalog,
	},
	{
		id: 3,
		label: 'Доставка',
		path: AppRoutes.Delivery,
	},
	{
		id: 4,
		label: 'Контакты',
		path: AppRoutes.Contacts,
	},
	{
		id: 5,
		label: 'Компания',
		path: AppRoutes.Company,
	},
	{
		id: 6,
		label: 'Партнеры',
		path: AppRoutes.Partners,
	},
];






const TelInput = (props) => (
	<InputMask mask="+7\ (999) 999 99 99" value={props.value} onChange={props.onChange}>
		{(inputProps) => <FormItem
			type='tel'
			inputMode='tel'
			name='tel'
			mask="+7\ 999 999 99 99"
			required='required'
			placeholder='Ваш телефон'
		/>}
	</InputMask>
);


export const Form = (props) => {
	const [formState, setFormState] = useState({
		isPending: false,
		isValid: false,
		formSent: false,
		error: ''
	});
	const [toSend, setToSend] = useState({
		name: '',
		tel: '',
		message: ''
	});
	useEffect(() => {
		validate();
	}, [toSend])

	const validate = () => {
		if( !toSend.name ){
			setFormState({...formState, isValid: false, error: 'Не заполнено имя'})
			return;
		}
		if( +toSend.tel.match(/\d/g)?.length !== 11 ){
			setFormState({...formState, isValid: false, error: 'Номер телефона введён не верно'})
			return;
		}
		setFormState({...formState, isValid: true, error: ''})
	}

	const handleChange = (e) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setFormState({...formState, isPending: true})

		if (formState.isValid) {
			send(
				'service_8htjksg',
				'template_yl68zuw',
				toSend,
				'LItYSCCPcUIYlnony'
			)
				.then((response) => {
					console.log('SUCCESS!', response.status, response.text);
					setFormState({...formState, isPending: false});
					setFormState({...formState, formSent: true})
				})
				.catch((err) => {
					console.log('FAILED...', err);
					setFormState({...formState, isPending: false})
				});
		}

	};

	const location = useLocation().pathname;
	return (
		<FormWrapperMain>
			<Burger />
			<Container>
				<HeaderWrapper>
					<StyledLink to='/'>
						<Logo src={LOGO} alt='' />
					</StyledLink>
					<NavBar>
						{links.map(({ id, label, path }) => (
							<StyledLink key={id} to={path}>
								<NavItem isActive={path === location}>{label}</NavItem>
							</StyledLink>
						))}
					</NavBar>
				</HeaderWrapper>
				<ContainerWrapper>
					<Title>Свяжитесь с нами!</Title>
					<DecsWrapper>
						<DecsAdress>
							<DescTitle>Наш адрес</DescTitle>
							<ContactLinkAdress
								onClick={() => copy(`г. Краснодар ул. Cимферопольская 55/1 оф. 2-10 `)}>
								<p>г. Краснодар</p>
								<p>ул. Cимферопольская</p>
								<p>55/1 оф. 2-10</p>
							</ContactLinkAdress>
						</DecsAdress>
						<Line />
						<DecsPhones>
							<DescTitle>Наш телефон</DescTitle>
							<ContactLink href='tel:8 861 260 47 20'>8 861 260 47 20</ContactLink>
							<ContactLink href='tel:8 918 250 66 10'>8 918 250 66 10</ContactLink>
						</DecsPhones>
						<Line />
						<DecsMail>
							<DescTitle>Наша почта</DescTitle>
							<ContactLink href='mailto:gk-energosila@mail.ru'>
								gk-energosila@mail.ru
							</ContactLink>
						</DecsMail>
					</DecsWrapper>
					<Lable>
						Вы можете задать интересующие вас вопросы отправив нам заявку
					</Lable>
					<FormWrapper onChange={validate} onSubmit={onSubmit}>
						<FormItem
							type='text'
							name='name'
							inputMode='text'
							placeholder='Ваше имя'
							required='required'
							value={toSend.name}
							onChange={handleChange}
							onFocus={handleChange}
						/>
						<TelInput
							value={toSend.tel}
							onChange={handleChange}
							onFocus={handleChange}
						/>
						<FormField
							type='text'
							name='message'
							inputMode='text'
							required='required'
							placeholder='Ваше сообщение'
							value={toSend.message}
							onChange={handleChange}
							onFocus={handleChange}
							onBlur={handleChange}
						/>
						<Button disabled={!formState.isValid} type='submit'>{formState.isPending ? <Loader src={'/images/loader.svg'}/> : 'Отправить заявку'}</Button>
						{formState.formSent ? <FormResult>Форма успешно отправлена</FormResult> : ( formState.error ? <FormResult>{formState.error}</FormResult> : '' )}
					</FormWrapper>
				</ContainerWrapper>
			</Container>
		</FormWrapperMain>
	);
};

// export const Form = (props) => {
// 	return (
// 		<FormWrapperMain>
// 			<Container>
// 				<Header />
// 				<ContainerWrapper>
// 					<Title>Свяжитесь с нами!</Title>
// 					<DecsWrapper>
// 						<DecsAdress>
// 							<DescTitle>Наш адрес</DescTitle>
// 							<ContactLink
// 								onClick={() => copy(`г. Краснодар ул. Cимферопольская 55/1 оф. 2-10 `)}>
// 								г. Краснодар
// 								<br />
// 								ул. Cимферопольская
// 								<br /> 55/1 оф. 2-10
// 							</ContactLink>
// 						</DecsAdress>
// 						<Line />
// 						<DecsPhones>
// 							<DescTitle>Наш телефон</DescTitle>
// 							<ContactLink href='tel:8 961 260 47 20'>8 961 260 47 20</ContactLink>
// 							<ContactLink href='tel:8 918 250 66 10'>8 918 250 66 10</ContactLink>
// 						</DecsPhones>
// 						<Line />
// 						<DecsMail>
// 							<DescTitle>Наша почта</DescTitle>
// 							<ContactLink href='mailto:gk-energosila@mail.ru'>
// 								gk-energosila@mail.ru
// 							</ContactLink>
// 						</DecsMail>
// 					</DecsWrapper>
// 					<Lable>
// 						Вы можете задать интересующие вас вопросы отправив нам заявку
// 					</Lable>
// 					<FormWrapper>
// 						<FormItem
// 							type='text'
// 							inputMode='text'
// 							placeholder='Ваше имя'
// 							required='required'
// 						/>
// 						<FormItem
// 							type='tel'
// 							inputMode='tel'
// 							pattern='\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
// 							required='required'
// 							placeholder='Ваш телефон'
// 						/>
// 						<FormField
// 							type='text'
// 							inputMode='text'
// 							required='required'
// 							placeholder='Ваше сообщение'
// 						/>
// 						<Button type='submit'>Отправить заявку</Button>
// 					</FormWrapper>
// 				</ContainerWrapper>
// 			</Container>
// 		</FormWrapperMain>
// 	);
// };
