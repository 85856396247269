import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonForm from '../../components/ButtonForm';
import ButtonNext from '../../components/ButtonNext';
import Burger from '../../components/Burger/burger';
import { AppRoutes } from '../../constants';
import { Wrapper } from '../../constants/wrappers';

import {
	Title,
	SubTitle,
	Lable,
	SubLable,
	DescTime,
	DescAdress,
	DescBonus,
	TextLine,
	CaptionOne,
	CaptionTwo,
	CaptionThree,
	ColumnOne,
	ContentWrapper,
	ButtonWrapper,
	RowTwo,
	RowThree,
	RowFour,
	RowFive,
	ContentRightWrapper,
	RowTwoText,

} from './style';

export const Delivery = (props) => {
	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<ContentWrapper>
					<ColumnOne>
						<Title>Доставка</Title>
						<Lable>По Краснодарскому краю, республике Крым и России</Lable>
						<SubLable>Забрать самому со склада</SubLable>
						<DescTime>Пн-Пт с 9:00 до 17:30</DescTime>
						<DescAdress>г. Краснодар, улица Демуса 30</DescAdress>
						<DescBonus>
							При заказе от 50 000р. и более, бесплатная доставка
							<br />
							по Краснодару.
						</DescBonus>
					</ColumnOne>
					<ContentRightWrapper>
					<RowTwo>
						<SubTitle>Преимущества доставки</SubTitle>
						<RowTwoText>
							<TextLine>
								При единовременном заказе товаров на сумму 500 000 рублей и более
								доставка бесплатно.
							</TextLine>
							<TextLine>
								По требованию заказчика могут быть изготовлены изделия не представленные
								в каталоге, либо типовые изделия выполненные под размерные и
								конструктивные требования предоставленные заказчиком
							</TextLine>
						</RowTwoText>
					</RowTwo>
					<RowThree>
						<CaptionOne>Оперативность</CaptionOne>
						<TextLine>
							Склад в Краснодаре и постоянное наличие на складе всего ассортимента
							товаров, позволяет компании сократить время формирования и отправки
							заказа до минимума.
						</TextLine>
					</RowThree>
					<RowFour>
						<CaptionTwo>Сохранность</CaptionTwo>
						<TextLine>
							Компания обеспечивает максимально высокий уровень сохранности грузов при
							транспортировке, рационального размещения и привлечения компетентных
							перевозчиков.
						</TextLine>
					</RowFour>
					<RowFive>
						<CaptionThree>Надежность</CaptionThree>
						<TextLine>
							Компания имеет свой автотранспорт, а также сотрудничает с надежными и
							проверенными перевозчиками.
						</TextLine>
					</RowFive>
					</ContentRightWrapper>
				</ContentWrapper>
				<ButtonWrapper>
					<ButtonForm />
					<ButtonNext path={AppRoutes.Contacts} text='Узнать контакты' />
				</ButtonWrapper>
				<Footer />
			</Container>
		</Wrapper>
	);
};

{
	/* <ColumnTwo>
						<SubTitle>Преимущества доставки</SubTitle>
						<TextLine>
							При единовременном заказе товаров на сумму 500 000 рублей и более
							доставка бесплатно.
						</TextLine>
						<CaptionOne>Оперативность</CaptionOne>
						<CaptionTwo>Сохранность</CaptionTwo>
						<CaptionThree>Надежность</CaptionThree>
					</ColumnTwo>
					<ColumnThree>
						<TextLine>
							По требованию заказчика могут быть изготовлены изделия не представленные
							в каталоге, либо типовые изделия выполненные под размерные и
							конструктивные требования предоставленные заказчиком
						</TextLine>
						<TextLine>
							Склад в Краснодаре и постоянное наличие на складе всего ассортимента
							товаров, позволяет компании сократить время формирования и отправки
							заказа до минимума.
						</TextLine>
						<TextLine>
							Компания обеспечивает максимально высокий уровень сохранности грузов при
							транспортировке, рационального размещения и привлечения компетентных
							перевозчиков.
						</TextLine>
						<TextLine>
							Компания имеет свой автотранспорт, а также сотрудничает с надежными и
							проверенными перевозчиками.
						</TextLine>
					</ColumnThree> */
}

// export const Delivery = (props) => {
// 	return (
// 		<Wrapper>
// 			<Burger />
// 			<Container>
// 				<Header />
// 				<HeaderText>
// 					<Title>Доставка</Title>
// 					<SubTitle>Преимущества доставки</SubTitle>
// 				</HeaderText>
// 				<ContentWrapper>
// 					<ColumnOne>
// 						<Lable>По Краснодарскому краю, республике Крым и России</Lable>
// 						<SubLable>Забрать самому со склада</SubLable>
// 						<DescTime>Пн-Пт с 9:00 до 17:30</DescTime>
// 						<DescAdress>г. Краснодар, улица Демуса 30</DescAdress>
// 						<DescBonus>
// 							При заказе от 50 000р. и более, бесплатная доставка
// 							<br />
// 							по Краснодару.
// 						</DescBonus>
// 					</ColumnOne>
// 					<ColumnTwo>
// 						<TextLine>
// 							При единовременном заказе товаров на сумму 500 000 рублей и более
// 							доставка бесплатно.
// 						</TextLine>
// 						<CaptionOne>Оперативность</CaptionOne>
// 						<CaptionTwo>Сохранность</CaptionTwo>
// 						<CaptionThree>Надежность</CaptionThree>
// 					</ColumnTwo>
// 					<ColumnThree>
// 						<TextLine>
// 							По требованию заказчика могут быть изготовлены изделия не представленные
// 							в каталоге, либо типовые изделия выполненные под размерные и
// 							конструктивные требования предоставленные заказчиком
// 						</TextLine>
// 						<TextLine>
// 							Склад в Краснодаре и постоянное наличие на складе всего ассортимента
// 							товаров, позволяет компании сократить время формирования и отправки
// 							заказа до минимума.
// 						</TextLine>
// 						<TextLine>
// 							Компания обеспечивает максимально высокий уровень сохранности грузов при
// 							транспортировке, рационального размещения и привлечения компетентных
// 							перевозчиков.
// 						</TextLine>
// 						<TextLine>
// 							Компания имеет свой автотранспорт, а также сотрудничает с надежными и
// 							проверенными перевозчиками.
// 						</TextLine>
// 					</ColumnThree>
// 				</ContentWrapper>
// 				<ButtonWrapper>
// 					<ButtonForm />
// 					<ButtonNext path={AppRoutes.Contacts} text='Узнать контакты' />
// 				</ButtonWrapper>
// 				<Footer />
// 			</Container>
// 		</Wrapper>
// 	);
// };
