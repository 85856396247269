import React from 'react';
import { Wrapper, Label } from './style';

const Footer = () => {
	return (
		<Wrapper>
			<Label>
				© 2018-2022
				<br /> ООО “ГРУППА КОМПАНИЙ ЭНЕРГОСИЛА”
			</Label>
			<Label>
				Продажа кабеля, металлических
				<br /> кабельных лотков и аксессуаров ,<br /> граненных опор освещения
			</Label>
		</Wrapper>
	);
};

export default Footer;
