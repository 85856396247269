import styled from 'styled-components';
import { device } from '../../constants/size';
import theme from '../../constants/theme';

export const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
	flex-direction: column;
	position: absolute;
	bottom: 35px;

	@media (max-width: 1500px){
		display: none;
	}
	@media (max-height: 950px) {
		display: none;
	}
`;

export const Label = styled.h6`
	font-weight: 400;
	font-size: 17px;
	line-height: 21px;
	color: ${theme.color.white};

	&:last-child {
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
	}

	@media ${device.desktop} {
		font-size: 15px;
		line-height: 19px;

		&:last-child {
			font-weight: 300;
			font-size: 12px;
			line-height: 15px;
		}
	}

	@media ${device.dekstopS} {
		font-size: 13px;
		line-height: 17px;

		&:last-child {
			font-weight: 300;
			font-size: 10px;
			line-height: 13px;
		}
	}
`;
