import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { LOGO } from '../../constants/images';

import {
	Wrapper,
	StyledLink,
	NavBar,
	NavItem,
	ContactsBlock,
	ContactLink,
	Logo,
} from './style';

const links = [
	{
		id: 1,
		label: 'Главная',
		path: AppRoutes.Home,
	},
	{
		id: 2,
		label: 'Каталог',
		path: AppRoutes.Catalog,
	},
	{
		id: 3,
		label: 'Доставка',
		path: AppRoutes.Delivery,
	},
	{
		id: 4,
		label: 'Контакты',
		path: AppRoutes.Contacts,
	},
	{
		id: 5,
		label: 'Компания',
		path: AppRoutes.Company,
	},
	{
		id: 6,
		label: 'Партнеры',
		path: AppRoutes.Partners,
	},
];

const Header = () => {
	const location = useLocation().pathname;

	return (
		<Wrapper>
			<StyledLink to='/'>
				<Logo src={LOGO} alt='' />
			</StyledLink>
			<NavBar>
				{links.map(({ id, label, path }) => (
					<StyledLink key={id} to={path}>
						<NavItem isActive={path === location}>{label}</NavItem>
					</StyledLink>
				))}
			</NavBar>
			<ContactsBlock>
				<div>
					<ContactLink href='tel:8 861 260 47 20' large>
						8 861 260 47 20
					</ContactLink>
					<ContactLink href='tel:8 918 250 66 10' large>
						8 918 250 66 10
					</ContactLink>
				</div>
				<ContactLink>г. Краснодар, ул. Cимферопольская 55/1 оф. 2-10</ContactLink>
				<ContactLink href='mailto:gk-energosila@mail.ru'>
					gk-energosila@mail.ru
				</ContactLink>
			</ContactsBlock>
		</Wrapper>
	);
};

export default Header;
