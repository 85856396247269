import styled from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

export const Title = styled.p`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};
	margin: 0 0 30px 0;

	@media ${device.desktop} {
		font-size: 32px;
		line-height: 40px;
	}

	@media ${device.laptopL} {
		font-size: 30px;
		line-height: 38px;
	}

	@media ${device.laptop} {
		font-size: 28px;
		line-height: 1.5;
		text-align: center;
		margin: 0;
	}

	@media ${device.tablet} {
		font-size: 26px;
	}
	@media ${device.mobileL} {
		font-size: 24px;
	}
	@media ${device.mobileM} {
		font-size: 22px;
	}
	@media ${device.mobileS} {
		font-size: 20px;
	}
`;
export const SubTitle = styled.p`
	font-weight: 700;
	font-size: 30px;
	line-height: 37px;
	color: ${theme.color.white};
	border-bottom: 2px solid ${theme.color.bronze};
	margin: 0 0 30px 0;
	padding: 0 0 10px 0;

	@media ${device.desktop} {
		font-size: 27px;
		line-height: 34px;
	}

	@media ${device.laptopL} {
		font-size: 25px;
		line-height: 32px;
	}

	@media ${device.laptop} {
		margin: 0 0 10px 0;
		line-height: 1.5;
		text-align: center;
	}

	@media ${device.tablet} {
		font-size: 23px;
	}
	@media ${device.mobileL} {
		font-size: 21px;
	}
	@media ${device.mobileM} {
		font-size: 19px;
	}
	@media ${device.mobileS} {
		font-size: 18px;
	}
`;
export const Lable = styled.p`
	font-weight: 700;
	font-size: 23px;
	line-height: 28px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 21px;
		line-height: 26px;
	}

	@media ${device.laptopL} {
		font-size: 18px;
		line-height: 24px;
		text-align: center;
	}

`;
export const SubLable = styled.p`
	font-weight: 800;
	font-size: 25px;
	line-height: 30px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 23px;
		line-height: 28px;
	}

	@media ${device.laptopL} {
		font-size: 21px;
		line-height: 26px;
	}

	@media ${device.laptop} {
		font-size: 18px;
		line-height: 1.5;
		text-align: center;
	}
`;

const Desc = styled.p`
	font-size: 23px;
	line-height: 28px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 21px;
		line-height: 26px;
	}

	@media ${device.laptopL} {
		font-size: 19px;
		line-height: 24px;
	}

	@media ${device.laptop} {
		font-size: 18px;
		line-height: 1.5;
		text-align: center;
	}
`;

export const DescTime = styled(Desc)`
	font-weight: 500;
`;
export const DescAdress = styled(Desc)`
	font-weight: 400;
`;
export const DescBonus = styled.p`
	font-weight: 400;
	font-size: 19px;
	line-height: 23px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 18px;
		line-height: 21px;
	}

	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 19px;
	}

	@media ${device.laptop} {
		line-height: 1.5;
		text-align: center;
	}
`;

// Caption

const Caption = styled.p`
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	color: ${theme.color.white};
	padding: 0 0 0 10px;
	flex: 60%;
	border-left: 2px solid ${theme.color.bronze};

	&:first-child {
		flex: 15%;
		padding: 0 20px 0 10px;
	}

	@media ${device.desktop} {
		font-size: 18px;
		line-height: 22px;
	}

	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 20px;
	}

	@media ${device.laptop} {
		margin: 0 0 20px 0;
		padding: 0;
		line-height: 1.5;
		border-left: none;
		text-align: center;
		margin: 0;
		font-weight: 400;

		&:first-child {
			border-bottom: 2px solid ${theme.color.bronze};
			padding: 0 0 15px 0;
		}
	}

	@media ${device.tablet} {
	}
`;

export const CaptionOne = styled(Caption)`
	@media ${device.laptop} {
		font-size: 18px;
	}
`;

export const CaptionTwo = styled(Caption)`
	@media ${device.laptop} {
		font-size: 18px;
	}
`;

export const CaptionThree = styled(Caption)`
	@media ${device.laptop} {
		font-size: 18px;
	}
`;

export const TextLine = styled(Caption)``;

export const ContentWrapper = styled.div`
	display: flex;
	margin: 0 0 40px 0;
	gap: 40px;
	padding: 0 50px 0 0;

	@media ${device.laptop} {
		margin: 0;
		padding: 0;
		flex-direction: column;
		gap: 30px;
		align-items: center;
	}
`;

export const ContentRightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media ${device.laptop} {
		margin: 0 0 50px 0;
		gap: 20px;
		align-items: center;
	}
`;

// Columns ana Rows

export const ColumnOne = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 316px;
	gap: 15px;

	@media ${device.laptop} {
		gap: 20px;
		max-width: 100%;
		margin: 0 0 20px 0;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: 20px 0 0 0;

	@media ${device.laptop} {
		gap: 20px;
		flex-direction: column;
		align-items: center;
	}
`;

export const RowTwo = styled(Row)`
	margin: 0;
	flex-wrap: wrap;
`;
export const RowTwoText = styled.div`
	display: flex;
	@media ${device.laptop} {
		flex-direction: column;
		gap: 15px;
	}
`;

export const RowThree = styled(Row)`
	justify-content: space-between;
`;
export const RowFour = styled(Row)``;
export const RowFive = styled(Row)``;

// Button

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 40px;

	@media ${device.laptop} {
		flex-direction: column;
		gap: 30px;
		padding: 0 0 50px 0;
		align-items: center;
	}
`;
