import styled from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

// FLEX BOXS

export const Line = styled.div`
	height: 241px;
	border: 1px solid ${theme.color.bronze};
	margin: 0 73px 0 0;
	width: 0;

	@media ${device.laptopL} {
		height: 0;
		align-self: flex-start;
		width: 241px;
		margin: 0 0 50px 0;
	}

	@media ${device.tablet} {
		align-self: center;
	}
`;
// Врапер для верхней части контента
export const HeaderText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	max-width: 1500px;

	@media ${device.desktop} {
		padding: 0 5% 0 0;
	}

	@media ${device.laptopL} {
	}

	@media ${device.tablet} {
		text-align: center;
		align-items: center;
		padding: 0;
	}
`;
// Врапер для верхнего текста и всего остального
export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px 0 0 0;
	gap: 15px;

	@media ${device.desktop} {
		padding: 0 5% 0 0;
	}

	@media ${device.laptopL} {
	}

	@media ${device.tablet} {
		text-align: center;
		align-items: center;
		padding: 0;
	}
`;
// Врапер для двух частей контента
export const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 76px;

	@media ${device.laptopL} {
		flex-direction: column;
	}

	@media ${device.tablet} {
		text-align: center;
		align-items: center;
		gap: 50px;
	}
`;
// Врапер для левой части
export const Production = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 542px;
	gap: 20px;

	@media ${device.tablet} {
		text-align: center;
		align-items: center;
	}
`;
// Врапер для правой части
export const Advantage = styled.div`
	display: flex;
	flex-direction: column;
	gap: 55px;
	max-width: 717px;

	@media ${device.tablet} {
		gap: 50px;
		text-align: center;
		align-items: center;
	}
`;
// Врапер для правой части с линией
export const AdvantageLine = styled.div`
	display: flex;
	max-width: 673px;
	align-items: center;
	flex-direction: row;

	@media ${device.laptopL} {
		flex-direction: column;
	}

	@media ${device.tablet} {
		text-align: center;
		align-items: center;
	}
`;

// Врапер для кнопок
export const ButtonWrapper = styled.div`
	display: flex;
	gap: 43px;
	margin: 0 0 0 80px;

	@media ${device.laptopL} {
		padding: 0 0 100px 0;
		margin: 0;
	}

	@media ${device.tablet} {
		flex-direction: column;
		gap: 30px;
		text-align: center;
		align-items: center;
		padding: 0 0 50px 0;
	}
`;

// TEXTS

export const Title = styled.h1`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 32px;
		line-height: 40px;
	}

	@media ${device.laptopL} {
		font-size: 30px;
		line-height: 1.5;
	}

	@media ${device.tablet} {
		font-size: 28px;
	}
	@media ${device.mobileL} {
		font-size: 26px;
	}
	@media ${device.mobileM} {
		font-size: 24px;
	}
	@media ${device.mobileS} {
		font-size: 22px;
	}
`;
export const SubTitle = styled.h2`
	font-weight: 700;
	font-size: 30px;
	line-height: 37px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 27x;
		line-height: 35px;
	}

	@media ${device.laptopL} {
		font-size: 25px;
		line-height: 1.5;
	}

	@media ${device.tablet} {
		font-size: 23px;
	}
	@media ${device.mobileL} {
		font-size: 21px;
	}
	@media ${device.mobileM} {
		font-size: 19px;
	}
	@media ${device.mobileS} {
		font-size: 17px;
	}
`;
export const TextBronze = styled.h3`
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: ${theme.color.bronze};
`;
export const TextBronzeSpan = styled.span`
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 16px;
		line-height: 1.5;
	}
`;
export const DotWrapper = styled.ul`
	margin: 0 0 0 2%;
	@media ${device.tablet} {
		margin: 0;
	}
`;

export const DotItem = styled.li`
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: ${theme.color.white};
	margin: 0 0 0 20px;

	@media ${device.desktop} {
		font-size: 16px;
		line-height: 1.5;
	}

	@media ${device.tablet} {
		list-style-type: none;
		margin: 0;
		margin: 0 0 5px 0;
		font-weight: 400;
	}

	&:first-child {
		@media ${device.tablet} {
			padding: 10px 0 0 0;
		}
	}

	&:last-child {
		@media ${device.tablet} {
			padding: 0 0 10px 0;
		}
	}
`;
export const Text = styled.p`
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 16px;
		line-height: 20px;
	}

	@media ${device.tablet} {
		font-size: 16px;
		line-height: 1.5;
	}
`;
