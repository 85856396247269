import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonForm from '../../components/ButtonForm';
import ButtonNext from '../../components/ButtonNext';
import Burger from '../../components/Burger/burger';
import { ARROW } from '../../constants/images';
import { useState } from 'react';
import { AppRoutes } from '../../constants';
import { list } from '../../constants/list';
import { Wrapper } from '../../constants/wrappers';
import {
	ContentWrapper,
	ListWrapper,
	ListRow,
	ListItem1,
	ListItem2,
	ListItem3,
	ListItem4,
	ListItem5,
	ListItem6,
	ListItem7,
	ListItem8,
	ListItem9,
	ButtonWrapper,
	Title,
	WrapperCard,
	CloseBtn,
	TitleCard,
	TextCard,
	StyledLi,
	StyledUl,
} from './style';

export const Catalog = () => {
	const [active, setActive] = useState(false);
	const [currentId, setCurrentId] = useState(0);

	const onClose = () => {
		setActive(!active);
	};

	const listArray = list.map(({ id, title, text }) => (
		<WrapperCard key={id}>
			<CloseBtn src={ARROW} onClick={onClose} />
			<TitleCard>{title}</TitleCard>
			<TextCard>
				<StyledUl>
					{text.map((i) => {
						return <StyledLi>{i.item}</StyledLi>;
					})}
				</StyledUl>
			</TextCard>
		</WrapperCard>
	));

	const click = (с) => {
		setActive(!active);
		setCurrentId(с);
	};

	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<ContentWrapper>
					<Title>КАТАЛОГ</Title>
					<ListWrapper >
						{active && listArray[currentId]}
						<ListRow>
							<ListItem1 className={active ? 'active' : ''} onClick={() => click(0)}>Кабельная продукция</ListItem1>
							<ListItem2 className={active ? 'active' : ''} onClick={() => click(1)}>Кабеленесущие системы</ListItem2>
							<ListItem3 className={active ? 'active' : ''} onClick={() => click(2)}>Измерительные приборы</ListItem3>
						</ListRow>
						<ListRow>
							<ListItem4 className={active ? 'active' : ''} onClick={() => click(3)}>Светотехника</ListItem4>
							<ListItem5 className={active ? 'active' : ''} onClick={() => click(4)}>
								Низковольтное оборудование
							</ListItem5>
							<ListItem6 className={active ? 'active' : ''} onClick={() => click(5)}>
								Звонки дверные, домофонные системы
							</ListItem6>
						</ListRow>
						<ListRow>
							<ListItem7 className={active ? 'active' : ''} onClick={() => click(6)}>Электрические изделия</ListItem7>
							<ListItem8 className={active ? 'active' : ''} onClick={() => click(7)}>
								Системы обогрева, <br />
								вентиляции, <br />
								климатотехника
							</ListItem8>
							<ListItem9 className={active ? 'active' : ''} onClick={() => click(8)}>
								Пожарные/охранные <br />
								системы сигнализации
							</ListItem9>
						</ListRow>
					</ListWrapper>
					<ButtonWrapper>
						<ButtonForm />
						<ButtonNext path={AppRoutes.Delivery} text='Узнать о доставке' />
					</ButtonWrapper>
				</ContentWrapper>
				<Footer />
			</Container>
		</Wrapper>
	);
};
