import React from 'react';
import Menu from '../burgerMenu/burgerMenu';
import { Wrapper, WrapperItem } from './style';
import { useState } from 'react';

const Burger = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Wrapper className={open ? 'open' : ''} onClick={() => setOpen(!open)}>
				<WrapperItem />
				<WrapperItem />
				<WrapperItem />
			</Wrapper>
			{open && <Menu />}
		</>
	);
};

export default Burger;
