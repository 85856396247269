import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${theme.color.white};
`;

export const NavBar = styled.ul`
	display: flex;
	align-items: center;
	gap: 34px;
	margin: 0 20px 0 0;
	@media ${device.desktop} {
		gap: 30px; 
	}

	@media ${device.dekstopS} {
		display: none;
	}
`;

export const NavItem = styled.li`
	list-style: none;
	transition: 0.3s;
	border-bottom: ${({ isActive }) =>
		isActive ? `2px solid ${theme.color.bronze}` : 'none'};
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	padding-bottom: 4px;

	&:hover {
		opacity: 0.6;
	}

	@media ${device.desktop} {
		font-size: 17px;
		line-height: 20px;

	}
`;

export const ContactsBlock = styled.div`
	display: flex;
	gap: 10px;
	align-items: flex-start;
	flex-direction: column;
	padding-top: 68px;

	@media ${device.dekstopS} {
		display: none;
	}
`;

export const ContactLink = styled.a`
	text-decoration: none;
	font-weight: 400;
	font-size: ${({ large }) => (large ? '18px' : '14px')};
	line-height: 21px;
	color: ${theme.color.white};

	&:first-child {
		margin-right: 17px;
	}

	@media ${device.desktop} {
		font-size:  ${({ large }) => (large ? '16px' : '12px')};
		line-height: 18px;
	}
`;


export const Logo = styled.img`

	@media ${device.desktop} {
		width: 85%;
	}

	@media (max-width: 1600px) {
		padding: 40px 0 40px 0;
	}

	@media ${device.laptopL} {
		width: 75%;
	}

	@media ${device.laptop} {
		width: 65%;
		padding: 30px 0 30px 0;
	}
`;