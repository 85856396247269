import styled, { keyframes } from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

// Анимация чтобы плитки не наезжали друг на друга

const front = keyframes`
	0% { z-index: 0; }
	100% { z-index: 4; }
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 0 5vw 0 0;

	@media ${device.desktop} {
		padding: 0 5vw 0 0;
	}

	@media (max-height: 900px) {
		padding-right: 0;
	}

	@media ${device.tablet} {
		padding: 0 5vw 0 5vw;
		align-items: center;
	}
`;
export const ListWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;

	@media ${device.tablet} {
		gap: 0;
		height: 100%;
	}

	@keyframes action {
		0% {
			transform: (1);
		}
		100% {
			transform: (1.01);
		}
	}
`;
export const ListRow = styled.div`
	display: flex;
	max-width: 1460px;
	height: 150px;

	@media ${device.tablet} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	max-width: 623px;
	gap: 26px;

	@media ${device.tablet} {
		gap: 20px;
		flex-direction: column;
		align-items: center;
		padding: 0 0 50px 0;
	}
`;

// ListItems

export const ListItem1 = styled.button`
	width: 600px;
	height: 150px;
	background: ${theme.color.bronze};
	color: ${theme.color.black};
	font-size: 30px;
	line-height: 37px;
	border: none;
	font-weight: 600;
	transition: 0.3s;

	&.active:hover {
		transform: none;
	}

	&:hover {
		transform: scale(1.01);
		animation: ${front} 0s forwards;
	}

	@media ${device.desktop} {
		font-size: 27px;
		line-height: 34px;
	}

	@media ${device.laptopL} {
		font-size: 25px;
		line-height: 32px;
	}

	@media ${device.tablet} {
		font-size: 23px;
		line-height: 29px;
		width: 100%;
		max-height: 150px;
		border-bottom: 2px solid ${theme.color.grayBackground};
		height: 140px;
	}

	@media ${device.mobileL} {
		font-size: 21px;
		line-height: 27px;
		height: 130px;
	}

	@media ${device.mobileM} {
		font-size: 19px;
		line-height: 25px;
		height: 120px;
	}

	@media ${device.mobileS} {
		font-size: 17px;
		line-height: 23px;
		height: 110px;
	}
`;

export const ListItem2 = styled.button`
	width: 377px;
	height: 150px;
	background: ${theme.color.veryLightBronze};
	color: ${theme.color.black};
	font-size: 23px;
	line-height: 28px;
	border: none;
	font-weight: 600;
	transition: 0.3s;

	&.active:hover {
		transform: none;
		
	}

	&:hover {
		transform: scale(1.01);
		animation: ${front} 0s forwards;
	}

	@media ${device.desktop} {
		font-size: 20px;
		line-height: 25px;
	}

	@media ${device.laptopL} {
		font-size: 18px;
		line-height: 23px;
	}

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 1.5;
		width: 100%;
		border-bottom: 2px solid ${theme.color.grayBackground};
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem3 = styled.button`
	width: 483px;
	height: 150px;
	background: ${theme.color.lightBronze};
	color: ${theme.color.black};
	font-size: 25px;
	line-height: 30px;
	border: none;
	font-weight: 600;
	transition: 0.3s;

	&:hover {
		transform: scale(1.01);
		animation: ${front} 0s forwards;
	}

	&.active:hover {
		transform: none;
	}

	@media ${device.desktop} {
		font-size: 22px;
		line-height: 27px;
	}

	@media ${device.laptopL} {
		font-size: 20px;
		line-height: 21px;
	}

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 1.5;
		width: 100%;
		border-bottom: 2px solid ${theme.color.grayBackground};
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;

export const ListItemAfter = styled.button`
	font-size: 23px;
	line-height: 28px;
	font-weight: 600;
	border: none;
	transition: 0.3s;

	&.active:hover {
		transform: none;
	}

	&:hover {
		transform: scale(1.01);
		animation: ${front} 0s forwards;
	}

	@media ${device.desktop} {
		font-size: 20px;
		line-height: 25px;
	}

	@media ${device.laptopL} {
		font-size: 18px;
		line-height: 23px;
	}

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 1.5;
		width: 100%;
		border-bottom: 2px solid ${theme.color.grayBackground};
	}
`;

export const ListItem4 = styled(ListItemAfter)`
	width: 382px;
	height: 150px;
	background: ${theme.color.veryLightBronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem5 = styled(ListItemAfter)`
	width: 479px;
	height: 150px;
	background: ${theme.color.lightBronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem6 = styled(ListItemAfter)`
	width: 600px;
	height: 150px;
	background: ${theme.color.bronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem7 = styled(ListItemAfter)`
	width: 460px;
	height: 150px;
	background: ${theme.color.bronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem8 = styled(ListItemAfter)`
	width: 517px;
	height: 150px;
	background: ${theme.color.veryLightBronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;
export const ListItem9 = styled(ListItemAfter)`
	width: 487px;
	height: 150px;
	background: ${theme.color.lightBronze};
	color: ${theme.color.black};

	@media ${device.tablet} {
		width: 100%;
		height: 140px;
	}

	@media ${device.mobileL} {
		height: 130px;
	}

	@media ${device.mobileM} {
		height: 120px;
	}

	@media ${device.mobileS} {
		height: 110px;
	}
`;

export const Title = styled.h1`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 32px;
		line-height: 40px;
	}

	@media ${device.tablet} {
		font-size: 30px;
		line-height: 1.5;
	}

	@media ${device.mobileL} {
		font-size: 28px;
	}

	@media ${device.mobileM} {
		font-size: 26px;
	}

	@media ${device.mobileS} {
		font-size: 24px;
	}
`;

// Card

export const WrapperCard = styled.div`
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #282828;
	z-index: 50;
	position: absolute;
	overflow: auto;
`;

export const TitleCard = styled.h2`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};
	text-align: center;
	margin: 2% 0 0 0;

	@media ${device.desktop} {
		font-size: 30px;
		line-height: 38px;
	}

	@media ${device.laptopL} {
		font-size: 28px;
		line-height: 36px;
		margin: 3% 0 0 0;
	}

	@media ${device.tablet} {
		font-size: 20px;
		line-height: 21px;
		margin: 4% 0 4% 7%;
		text-align: start;
	}

	@media ${device.mobileL} {

		font-size: 18px;
		line-height: 19px;
		margin: 50px 0 5% 8%;
	}

	@media ${device.mobileM} {
		font-size: 16px;
		line-height: 17px;
		margin: 50px 0 6% 8%;
	}

	@media ${device.mobileS} {
		font-size: 14px;
		line-height: 15px;
		margin: 50px 0 7% 8%;
	}
`;

export const TextCard = styled.h3`
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;
	color: ${theme.color.white};
	margin: 4%;
	text-align: start;

	@media ${device.desktop} {
		font-size: 22px;
		line-height: 27px;
	}

	@media ${device.laptopL} {
		font-size: 20px;
		line-height: 25px;
	}

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 23px;
		margin: 4% 7%;
	}

	@media ${device.mobileL} {
		font-size: 16px;
		line-height: 21px;
		margin: 4% 6%;
	}

	@media ${device.mobileM} {
		font-size: 14px;
		line-height: 19px;
		margin: 4% 7%;
	}

	@media ${device.mobileS} {
		font-size: 12px;
		line-height: 17px;
		margin: 4% 8%;
	}
`;

export const CloseBtn = styled.img`
	position: absolute;
	left: 3%;
	top: 9%;
	cursor: pointer;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		top: 8%;
	}

	@media ${device.laptop} {
		top: 7%;
	}

	@media ${device.tablet} {
		right: 5%;
		top: 2%;
		left: auto;
		height: auto;
		width: 70px;
	}

	@media ${device.mobileL} {
		height: auto;
		width: 60px;
	}

	@media ${device.mobileM} {
		height: auto;
		width: 50px;
	}

	@media ${device.mobileS} {
		height: auto;
		width: 40px;
	}
`;

export const StyledLi = styled.li`
	margin: 0 0 10px 0;
	transition: 0.3s;
	cursor: default;
	&:hover {
		opacity: 0.6;
	}
`;

export const StyledUl = styled.ul`
	list-style-type: none;
`;
