import styled from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

// Text

export const Title = styled.h1`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};

	@media ${device.laptop} {
		text-align: center;
	}

	@media ${device.tablet} {
		font-size: 28px;
		line-height: 36px;
	}

	@media ${device.mobileL} {
		font-size: 22px;
		line-height: 30px;
	}

	@media ${device.mobileM} {
		font-size: 21px;
		line-height: 29px;
	}

	@media ${device.mobileS} {
		font-size: 18px;
		line-height: 26px;
	}
`;

// Sub start

const Sub = styled.p`
	font-size: 25px;
	line-height: 28px;

	@media ${device.laptop} {
		text-align: center;
	}

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 1.5;
	}
	
	@media ${device.mobileL} {
		font-size: 16px;
		line-height: 19px;
	}

	@media ${device.mobileM} {
		font-size: 13px;
		line-height: 16px;
	}

	@media ${device.mobileS} {
		font-size: 11px;
		line-height: 13px;
	}
`;

export const SubTitle = styled(Sub)`
	font-weight: 700;
	color: ${theme.color.bronze};

	@media ${device.mobileL} {
		font-size: 18px;
	}

	@media ${device.mobileM} {
		font-size: 16px;
	}

	@media ${device.mobileS} {
		font-size: 13px;
	}
`;

export const Lable = styled(Sub)`
	font-weight: 600;
	color: ${theme.color.white};
`;

export const SubLable = styled(Sub)`
	font-weight: 500;
	color: ${theme.color.white};
`;

// Sub end

// Flex

export const FlexWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${device.laptop} {
		height: 100vh;
	}
`;

export const FlexText = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 509px;
	gap: 13px;
	margin: 150px 0 42px 0;

	@media ${device.laptop} {
		align-items: center;
		margin: 0 auto;
		padding: 100px 0 50px 0;
	}

	@media ${device.tablet} {
	
	}

	@media ${device.mobileL} {
		padding: 50px 0 40px 0;
	}

	@media ${device.mobileM} {
		padding: 50px 0 30px 0;
	}

	@media ${device.mobileS} {
		padding: 50px 0 20px 0;
	}
`;

export const FlexBtn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	margin: 0 0 100px 0;
	max-width: max-content;

	@media ${device.laptop} {
		margin: 0 auto;
	}
`;

// Image

export const ImageWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 63vw;

	@media ${device.laptop} {
		display: none;
	}
`;

export const Image = styled.img`
	display: block;
	object-fit: cover;
	width: 100%;
	height: auto;
	vertical-align: top;
	pointer-events: none;
`;
