import styled from 'styled-components';
import theme from '../../constants/theme';
import { Link } from 'react-router-dom';
import { device } from '../../constants/size';

export const ContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin: 0 auto;
	max-width: 540px;
	align-items: flex-start;

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
	}
`;

export const Title = styled.h1`
	font-weight: 600;
	font-size: 21px;
	line-height: 26px;
	color: ${theme.color.bronze};

	@media ${device.tablet} {
		text-align: center;
		line-height: 1.5;
	}
`;

export const DecsWrapper = styled.div`
	display: flex;
	margin: 0 auto;
	max-width: 560px;

	@media ${device.tablet} {
		flex-direction: column;
		width: 100%;
		gap: 10px;
	}
`;
export const DecsItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 17px;
	align-items: flex-start;

	@media ${device.tablet} {
		flex-direction: row;
		align-items: center;
		gap: 10px;
		justify-content: center;
		flex-wrap: wrap;

	}
`;
export const DecsAdress = styled(DecsItem)`
	margin: 0 18px 0 0;

	@media ${device.tablet} {
		margin: 0;
		
	}	
`;
export const DecsPhones = styled(DecsItem)`
	margin: 0 22px 0 25px;

	@media ${device.tablet} {
		margin: 0;
	}
`;
export const DecsMail = styled(DecsItem)`
	margin: 0 0 0 25px;

	@media ${device.tablet} {
		margin: 0;
	}
`;

export const Line = styled.div`
	height: 127px;
	border: 1px solid ${theme.color.bronze};

	@media ${device.tablet} {
		display: none;
	}
`;
export const DescTitle = styled.h2`
	font-weight: 600;
	font-size: 19px;
	line-height: 23px;
	color: ${theme.color.bronze};

	@media ${device.tablet} {
		line-height: 1.5;
		font-size: 18px; 
		font-weight: 400;
		width: 100%;
		text-align: center;
	}
`;

export const ContactLink = styled.a`
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${theme.color.white};

	@media ${device.tablet} {
		line-height: 1.5;
		font-size: 16px; 
		font-weight: 400;
	}

	@media ${device.mobileL} {
		font-size: 14px;
	}
	@media ${device.mobileM} {
		font-size: 12px;
	}
	@media ${device.mobileS} {
		font-size: 11px;
	}
`;

export const ContactLinkAdress = styled(ContactLink)`
	display: flex;
	flex-direction: column;
	gap: 5px;

	@media ${device.tablet} {
		flex-direction: row;
		line-height: 1.5;
		font-weight: 400;
	}
`

export const Lable = styled.h3`
	font-weight: 600;
	font-size: 21px;
	line-height: 26px;
	color: ${theme.color.white};
	max-width: 326px;

	@media ${device.tablet} {
		font-size: 18px;
		line-height: 1.5;
	}
`;

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	gap: 14px;

	@media ${device.tablet} {
		align-items: center;
		align-self: center;
		margin: 0 auto;
	}
`;

export const FormItem = styled.input`
	font-size: 14px;
	line-height: 17px;
	width: 540px;
	height: 40px;
	font-size: 14px;
	padding: 10px;
	box-shadow: 0 0 15px 4px rgba(255, 228, 196, 0.1);

	
	@media ${device.tablet} {
		text-align: center;
		width: 400px;
	}

	@media ${device.mobileL} {
		width: 350px;
	}

	@media ${device.mobileM} {
		width: 300px;
	}
	
	
	@media ${device.mobileM} {
		width: 250px;
	}


	font-weight: 500;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #282828;
	outline: none;
	resize: none;
	wrap: off;
	overflow: hidden;
	color: ${theme.color.white};
	border: 1;
	border-style: solid;
	transition: 0.3s;
	border-color: ${theme.color.grayBackground};

	&:focus {
		border-color: ${theme.color.veryLightBronze};
	}

	&:-webkit-input-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
	}
	&:-moz-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
	}
	&:-moz-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
	}
	&:-ms-input-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
	}
	&:focus::-webkit-input-placeholder {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&:focus::-moz-placeholder {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&:focus:-moz-placeholder {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&:focus:-ms-input-placeholder {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
`;

export const FormField = styled(FormItem)`
	height: 100px;

	@media ${device.mobileM} {
		height: 80px;
	}
`;

export const Loader = styled.img``;

export const FormResult = styled.div`
	text-align: center;
	margin: 10px 0;
	font-size: 19px;
	color: ${theme.color.white};

	@media ${device.tablet} {
		margin: 10px 0 50px 0;
		font-size: 16px;
	}
`;

export const Button = styled.button`
	appearance: none;
	-webkit-appearance: none;
	background: ${theme.color.bronze};
	border-radius: 25px;
	width: 295px;
	height: 66px;
	font-weight: 600;
	font-size: 19px;
	line-height: 23px;
	color: ${theme.color.black};
	border: none;
	box-shadow: 0 0 15px 5px rgba(247, 247, 247, 0.1);
	margin: 34px 0 0 0;
	align-self: center;
	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}

	@media ${device.tablet} {
		font-size: 16px;
		line-height: 1.5;
		width: 260px;
		height: 60px;
	}
	
	@media ${device.mobileL} {
		width: 250px;
		height: 60px;
	}

`;

// Header

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	flex: 0 0 50%;
	padding: 45px 0 0 0;
	margin: 0 0 40px 0;
	justify-content: space-between;

	@media ${device.dekstopS} {
		margin: 0;
		padding: 0;
	}
`;
export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${theme.color.white};
`;

export const NavBar = styled.ul`
	display: flex;
	align-items: center;
	gap: 34px;
	flex: 50%;
	padding: 0 0 0 7%;
	transform: translateX(10%);

	@media ${device.dekstopS} {
		display: none;
	}
`;

export const NavItem = styled.li`
	list-style: none;
	transition: 0.3s;
	border-bottom: 2px solid ${theme.color.bronze};
	font-weight: 500;
	font-size: 19px;
	line-height: 23px;
	padding-bottom: 4px;

	&:hover {
		opacity: 0.6;
	}
`;

export const Logo = styled.img`
	@media ${device.desktop} {
		width: 85%;
	}

	@media (max-width: 1600px) {
		padding: 40px 0 40px 0;
	}

	@media ${device.laptopL} {
		width: 75%;
	}

	@media ${device.laptop} {
		width: 65%;
		padding: 30px 0 30px 0;
	}
`;
