import styled from 'styled-components';
import { device } from '../../constants/size';

export const Wrapper = styled.div`
	max-width: 85%;
	margin: 0 auto;
	position: relative;
	height: 100%;

	@media ${device.tablet} {
		max-width: 95%;
	}
`;
