import styled from 'styled-components';
import { device } from '../../constants/size';
import theme from '../../constants/theme';

export const Wrapper = styled.div`
	display: none;
	flex-direction: column;
	gap: 10px;
	position: absolute;
	top: 40px;
	right: 5%;
	cursor: pointer;
	z-index: 100;

	@media (max-width: 1600px) {
		display: flex;
	}

	@media ${device.laptop} {
		right: 10%;
		top: 30px;
	}

	@media ${device.mobileL} {
		gap: 8px;
	}

	@media ${device.mobileM} {
		gap: 6px;
		top: 35px;
	}

	@media ${device.mobileS} {
	}

	&.open div {
		transform: rotate(-45deg);
		width: 55px;
		position: absolute;
		top: 10px;
		left: -20px;

		@media ${device.tablet} {
			right: 1vw;
		}

		@media ${device.mobileM} {
			left: -25px;
		}

		@media ${device.mobileM} {
			left: -30px;
		}
	}
	&.open div:first-child {
		transform: rotate(45deg);
		width: 55px;
	}
	&.open div:last-child {
		display: none;
	}
`;
export const WrapperItem = styled.div`
	height: 5px;
	width: 50px;
	background-color: ${theme.color.bronze};

	@media ${device.mobileL} {
		width: 45px;
	}

	@media ${device.mobileM} {
		width: 35px;
		height: 4px;
	}

	@media ${device.mobileS} {
		width: 30px;
		height: 4px;
	}
`;
