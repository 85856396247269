import React from 'react';
import { LinkButton, StyledButtonNext } from './styled';
import { useLocation } from 'react-router-dom';

const ButtonNext = (props) => {
	const location = useLocation();
	return (
		<LinkButton to={props.path} {...props}>
			<StyledButtonNext isActive={props.path === location} {...props}>
				{props.text}
			</StyledButtonNext>
			;
		</LinkButton>
	);
};

export default ButtonNext;
