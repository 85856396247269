import { Routes, Route } from "react-router-dom";
import { AppRoutes } from "../../gkenergypower-landing/src/constants";
import { Home } from "../../gkenergypower-landing/src/pages/Home";
import { Catalog } from "../../gkenergypower-landing/src/pages/Catalog";
import { Delivery } from "../../gkenergypower-landing/src/pages/Delivery";
import { Contacts } from "../../gkenergypower-landing/src/pages/Contacts";
import { Company } from "../../gkenergypower-landing/src/pages/Company";
import { Partners } from "../../gkenergypower-landing/src/pages/Partners";
import { Form } from "../../gkenergypower-landing/src/pages/Form";

const App = () => {
  return (
    <Routes>
      <Route path={AppRoutes.Home} element={<Home />} />
      <Route path={AppRoutes.Catalog} element={<Catalog />} />
      <Route path={AppRoutes.Delivery} element={<Delivery />} />
      <Route path={AppRoutes.Contacts} element={<Contacts />} />
      <Route path={AppRoutes.Company} element={<Company />} />
      <Route path={AppRoutes.Partners} element={<Partners />} />
      <Route path={AppRoutes.Form} element={<Form />} />
    </Routes>
  );
};

export default App;
