import styled from 'styled-components';
import { device } from '../../constants/size';
import { Link } from 'react-router-dom';
import theme from '../../constants/theme';

export const MenuWrapper = styled.div`
	position: absolute;
	background-color: ${theme.color.grayBackground};
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	width: 30%;
	height: 100%;
	z-index: 50;
	border-left: 1px solid ${theme.color.lightBronze};
	border-right: 1px solid ${theme.color.lightBronze};

	@media ${device.laptop} {
		position: fixed;
		width: 100vw;
		border: none;
		overflow: auto;
		}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${theme.color.white};
`;

export const NavBar = styled.ul`
	width: 100%;
	height: 100%;
	background-color: ${theme.color.grayBackground};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	gap: 34px;
	margin: 0 20px 0 0;
`;

export const NavItem = styled.li`
	list-style: none;
	transition: 0.3s;
	border-bottom: ${({ isActive }) =>
		isActive ? `2px solid ${theme.color.bronze}` : 'none'};
	font-weight: 300;
	font-size: 30px;
	line-height: 37px;
	padding: 0 0 10px 0;

	&:hover {
		opacity: 0.6;
	}
	@media ${device.tablet} {
		font-size: 28px;
		line-height: 35px;
	}
	
	@media ${device.mobileL} {
		font-size: 26px;
		line-height: 33px;
	}

	@media ${device.mobileM} {
		font-size: 24px;
		line-height: 31px;
	}

	@media ${device.mobileS} {
		font-size: 22px;
		line-height: 29px;
	}
`;

export const ContactsBlock = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	flex-direction: column;
	flex: 50%;

	@media ${device.mobileL} {
		align-items: start;
	}
	
`;

export const ContactLink = styled.a`
	text-decoration: none;
	font-weight: 400;
	font-size: ${({ large }) => (large ? '14px' : '12px')};
	line-height: 15px;
	color: ${theme.color.white};
	text-align: center;

	@media ${device.mobileL} {
		text-align: start;
	}
`;
export const Logo = styled.img`
	margin: 30px 0 20px 0;
	width: 100%;
`;

export const FooterWrapper = styled.div`
	display: flex;
	flex: 1 1 50%;
	align-items: center;
	gap: 10px;
	margin: 0 10px;
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	flex-direction: column;
	flex: 50%;
	
`;

export const Label = styled.h6`
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: ${theme.color.white};
	text-align: center;

	&:last-child {
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
	}

	@media ${device.mobileL} {
		text-align: start;
	}
`;
