import React from 'react';
import { LinkButton, StyledButtonForm } from './styled';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';

const ButtonForm = (props) => {
	const location = useLocation();
	return (
		<LinkButton to={AppRoutes.Form}>
			<StyledButtonForm isActive={AppRoutes.Form === location} {...props}>
				Оформить заказ
			</StyledButtonForm>
		</LinkButton>
	);
};

export default ButtonForm;
