import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonForm from '../../components/ButtonForm';
import ButtonNext from '../../components/ButtonNext';
import Burger from '../../components/Burger/burger';
import { AppRoutes } from '../../constants';
import { Wrapper } from '../../constants/wrappers';

import {
	Line,
	HeaderText,
	ContentWrapper,
	TextWrapper,
	Production,
	Advantage,
	AdvantageLine,
	ButtonWrapper,
	Title,
	SubTitle,
	TextBronze,
	TextBronzeSpan,
	DotWrapper,
	DotItem,
	Text,
} from './style';

export const Company = (props) => {
	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<HeaderText>
					<Title>Группа компаний «ЭНЕРГОСИЛА»</Title>
					<div>
						<Text>
							<TextBronzeSpan>
								Любой современный строительный объект – это совокупность инженерных
								коммуникаций.
							</TextBronzeSpan>{' '}
							ООО «ГК ЭНЕРГОСИЛА» осуществляет комплексное снабжение материалами и
							комплектующими для создания инженерно-технических систем любой сложности.
							Наши специалисты помогут рассчитать потребность и укомплектовать заказ
							под ваш проект с учетом ваших пожеланий и предпочтений. Индивидуальный
							подход, доступные цены, проверенное качество – идеальное сочетание для
							вашего успеха.
						</Text>
					</div>
				</HeaderText>
				<ContentWrapper>
					<SubTitle>Преимущества сотрудничества с ГК «ЭНЕРГОСИЛА»</SubTitle>
					<TextWrapper>
						<Production>
							<TextBronze>
								Наша компания осуществляет поставки кабеля и сопутсвующей продукции от
								ведущих на рынке производителей
							</TextBronze>
							<Text>
								Кроме того, у нас есть собственные производственные мощности,
								осуществляющие изготовление кбеленесущих конструкций из оцинкованного
								металла:
							</Text>
							<DotWrapper>
								<DotItem>кабельные лотки</DotItem>
								<DotItem>полки</DotItem>
								<DotItem>стойки</DotItem>
								<DotItem>щиты различных размеров</DotItem>
							</DotWrapper>
							<Text>
								Вы можете выбрать изделия из листовой, рулонной или холоднокатаной стали
								с готовыми техническими отверстиями или без них. Все они адаптированы
								под простой монтаж - потолок, вертикальная и наклонная фиксация.
							</Text>
						</Production>
						<Advantage>
							<AdvantageLine>
								<Line></Line>
								<div>
									<TextBronze>
										Наши клиенты пользуются множеством преимуществ:
									</TextBronze>
									<DotWrapper>
										<DotItem>идеальное сочетание цены и качества</DotItem>
										<DotItem>высокая скорость исполнения заказа</DotItem>
										<DotItem>
											соответствие поставки самым высоким международным стандартам
										</DotItem>
										<DotItem>
											консультации профессионалов, четкая оранизация работ, быстрая и
											комфортная доставка в любой регион
										</DotItem>
										<DotItem>
											программа лояльности - гибкая система скидок для постоянных клиентов
											и крупных оптовых покупателей
										</DotItem>
									</DotWrapper>
								</div>
							</AdvantageLine>
							<ButtonWrapper>
								<ButtonForm />
								<ButtonNext path={AppRoutes.Partners}  text='Узнать о партнёрах' />
							</ButtonWrapper>
						</Advantage>
					</TextWrapper>
				</ContentWrapper>
				<Footer />
			</Container>
		</Wrapper>
	);
};
