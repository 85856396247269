const theme = {
  color: {
    white: "#F7F7F7",
    black: "#000",
    bronze: "#E4C47F",
    lightBronze: "#F2D8A1",
    veryLightBronze: "#F2DFB7",
    grayBackground: "#282828",
  },
};

export default theme;
