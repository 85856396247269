export const LOGO = "/images/logo.svg";
export const BACKGROUND = "/images/background.svg";
export const IMG_HOME = "/images/home.svg";
export const FORM_BACKGROUND = "/images/form.svg";
export const LOGO_MENU = "/images/logoMenu.svg";
export const ARROW = "/images/arrow.svg";
export const PARTNERS1 = "/images/partners-1.svg";
export const PARTNERS2 = "/images/partners-2.svg";
export const PARTNERS3 = "/images/partners-3.svg";
export const PARTNERS4 = "/images/partners-4.svg";
export const PARTNERS5 = "/images/partners-5.svg";
export const PARTNERS6 = "/images/partners-6.svg";