import React from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonForm from '../../components/ButtonForm';
import ButtonNext from '../../components/ButtonNext';
import Burger from '../../components/Burger/burger';
import { AppRoutes } from '../../constants';
import { copy } from '../../constants/copy';
import { Wrapper } from '../../constants/wrappers';
import {
	Line,
	ButtonWrapper,
	HeaderText,
	Account,
	AccountItem,
	LineWrapper,
	Requisites,
	RequisitesItem,
	NumbersContainer,
	NumbersContent,
	InfoContainer,
	InfoItem,
	Content,
	Title,
	SubTitle,
	Lable,
	Text,
	ContactLink,
} from './style';

export const Contacts = () => {
	return (
		<Wrapper>
			<Burger />
			<Container>
				<Header />
				<HeaderText>
					<Title>КОНТАКТЫ</Title>
					<SubTitle>Наименование получателя</SubTitle>
					<Text>
						Наименование получателя общество с ограниченной ответсвтенностью “ГРУППА
						КОМПАНИЙ «ЭНЕРГОСИЛА»<br></br>Директор - Григоренко Денис Евгеньевич,
						действует на основании Устава.
					</Text>
				</HeaderText>
				<Content>
					<InfoContainer>
						<InfoItem>
							<SubTitle>Наименование банка</SubTitle>
							<Text>
								Краснодарский региональный филиал<br></br>Акционерного общества<br></br>
								«Российский сельскохозяйственный банк»<br></br>Краснодарский РФ АО
								«Россельхозбанк»
							</Text>
						</InfoItem>
						<InfoItem>
							<SubTitle>Адрес</SubTitle>
							<Text
								onClick={() => copy('1172375104967')}
								style={{ cursor: 'pointer' }}>
								350080<br></br>Краснодарский край, г. Краснодар,<br></br>ул.
								Симферопольская 55/1, оф.2-10.
							</Text>
						</InfoItem>
						<InfoItem>
							<SubTitle>Почта</SubTitle>
							<ContactLink href='mailto:gk-energosila@mail.ru'>
								gk-energosila@mail.ru
							</ContactLink>
						</InfoItem>
						<InfoItem>
							<SubTitle>Телефон</SubTitle>
							<Text>
								<ContactLink href='tel:8 861 260 47 20'>8 861 260 47 20</ContactLink>
								<br></br>
								<ContactLink href='tel:8 918 250 66 10'>8 918 250 66 10</ContactLink>
							</Text>
						</InfoItem>
					</InfoContainer>
					<NumbersContainer>
						<LineWrapper>
							<Line></Line>
							<NumbersContent>
								<Requisites>
									<RequisitesItem>
										<Lable>ОГРН</Lable>
										<Text
											onClick={() => copy('1172375104967')}
											style={{ cursor: 'pointer' }}>
											1172375104967
										</Text>
									</RequisitesItem>
									<RequisitesItem>
										<Lable>КПП</Lable>
										<Text onClick={() => copy('231201001')} style={{ cursor: 'pointer' }}>
											231201001
										</Text>
									</RequisitesItem>
									<RequisitesItem>
										<Lable>ИНН</Lable>
										<Text
											onClick={() => copy('2312267638')}
											style={{ cursor: 'pointer' }}>
											2312267638
										</Text>
									</RequisitesItem>
									<RequisitesItem>
										<Lable>БИК</Lable>
										<Text onClick={() => copy('040349536')} style={{ cursor: 'pointer' }}>
											040349536
										</Text>
									</RequisitesItem>
								</Requisites>
								<Account>
									<AccountItem>
										<Lable>Корреспондентский счет</Lable>
										<Text
											onClick={() => copy('30101810700000000536')}
											style={{ cursor: 'pointer' }}>
											30101810700000000536
										</Text>
									</AccountItem>
									<AccountItem>
										<Lable>Расчетный счет</Lable>
										<Text
											onClick={() => copy('40702810103000001623')}
											style={{ cursor: 'pointer' }}>
											40702810103000001623
										</Text>
									</AccountItem>
								</Account>
							</NumbersContent>
						</LineWrapper>
						<ButtonWrapper>
							<ButtonForm />
							<ButtonNext path={AppRoutes.Company} text='Узнать о компании' />
						</ButtonWrapper>
					</NumbersContainer>
				</Content>
				{/* egneigieb */}
				<Footer />
			</Container>
		</Wrapper>
	);
};
