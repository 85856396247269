import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { LOGO_MENU } from '../../constants/images';

import {
	MenuWrapper,
	NavBar,
	StyledLink,
	NavItem,
	ContactsBlock,
	ContactLink,
	Logo,
	FooterWrapper,
	Footer,
	Label,
} from './style';

const links = [
	{
		id: 1,
		label: 'Главная',
		path: AppRoutes.Home,
	},
	{
		id: 2,
		label: 'Каталог',
		path: AppRoutes.Catalog,
	},
	{
		id: 3,
		label: 'Доставка',
		path: AppRoutes.Delivery,
	},
	{
		id: 4,
		label: 'Контакты',
		path: AppRoutes.Contacts,
	},
	{
		id: 5,
		label: 'Компания',
		path: AppRoutes.Company,
	},
	{
		id: 6,
		label: 'Партнеры',
		path: AppRoutes.Partners,
	},
];

const Menu = () => {
	const location = useLocation().pathname;
	return (
		<MenuWrapper>
			<StyledLink to='/'>
				<Logo src={LOGO_MENU} alt='' />
			</StyledLink>
			<NavBar>
				{links.map(({ id, label, path }) => (
					<StyledLink key={id} to={path}>
						<NavItem isActive={path === location}>{label}</NavItem>
					</StyledLink>
				))}
			</NavBar>
			<FooterWrapper>
				<Footer>
					<Label>
						© 2018-2022
						<br /> ООО “ГРУППА КОМПАНИЙ ЭНЕРГОСИЛА”
					</Label>
					<Label>
						Продажа кабеля, металлических кабельных лотков и аксессуаров , граненных
						опор освещения
					</Label>
				</Footer>
				<ContactsBlock>
					<div>
						<ContactLink href='tel:8 861 260 47 20' large>
							8 861 260 47 20
						</ContactLink>
						<br />
						<ContactLink href='tel:8 918 250 66 10' large>
							8 918 250 66 10
						</ContactLink>
					</div>
					<ContactLink>г. Краснодар, ул. Cимферопольская 55/1 оф. 2-10</ContactLink>
					<ContactLink href='mailto:gk-energosila@mail.ru'>
						gk-energosila@mail.ru
					</ContactLink>
				</ContactsBlock>
			</FooterWrapper>
		</MenuWrapper>
	);
};

export default Menu;
