import styled from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

// FLEX BOXS

export const Line = styled.div`
	height: 215px;
	border: 1px solid ${theme.color.bronze};
	margin: 0 70px 0 0;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		margin: 0 0 0 70px;
	}

	@media ${device.tablet} {
		width: 215px;
		height: 0;
		margin: 50px 0;
	}
`;

// Врапер для верхней части контента
export const HeaderText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 50px 0 0;

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		margin: 0 0 50px 0;
		gap: 10px;
	}
`;

// Врапер для контента
export const Content = styled.div`
	display: flex;
	margin: 25px 50px 0 0;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		flex-direction: column;
	}

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: 0;
	}
`;

// Врапер для правой части контента
export const NumbersContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 66px;

	@media ${device.desktop} {
		gap: 56px;
	}

	@media ${device.laptopL} {
		gap: 20px;
		flex-direction: row;
	}
	@media (max-width: 1300px) {
		flex-direction: column;
	}

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		gap: 30px;
	}
`;

// Врапер для левой части контента
export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	max-width: 464px;
	margin: 0 63px 0 0;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		margin: 0;
		flex-direction: row;
		max-width: 1050px;
	}

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 30px;
	}
`;

// Врапер для каждого итема в левой части
export const InfoItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		gap: 10px;
	}
`;

// Врапер для правой части контента с линией
export const LineWrapper = styled.div`
	display: flex;
	max-width: 666px;
	align-items: center;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;
// Врапер для правой части
export const NumbersContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;

	@media ${device.laptopL} {
		margin: 30px 0 0 0;
	}

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		margin: 0;
	}
`;
// Врапер для ревезитов
export const Requisites = styled.div`
	display: flex;
	gap: 43px;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 30px;
	}
`;
// Врапер для каждого итема реквизита
export const RequisitesItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		gap: 10px;
	}
`;
// Врапер для счетов
export const Account = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-width: 597px;

	@media ${device.tablet} {
		align-items: center;
		text-align: center;
		gap: 20px;
	}
`;
// Врапер для каждого счета
export const AccountItem = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 40px;
	margin: 0 0 0 30px;

	@media ${device.desktop} {
	}

	@media ${device.laptopL} {
		justify-content: center;
		margin: 0 20px 0 30px;
		flex-direction: column;
	}

	@media (max-width: 1300px) {
		flex-direction: row;
		margin: 0;
		align-self: flex-start;
	}

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: 30px 0 50px 0;
		align-self: center;
		gap: 30px;
	}
`;

// Texts

export const Title = styled.h1`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 32px;
		line-height: 40px;
	}

	@media ${device.laptopL} {
		font-size: 30px;
		line-height: 1.5;
	}

	@media ${device.tablet} {
		font-size: 28px;
	}
	@media ${device.mobileL} {
		font-size: 26px;
	}
	@media ${device.mobileM} {
		font-size: 24px;
	}
	@media ${device.mobileS} {
		font-size: 22px;
	}
`;
export const SubTitle = styled.h2`
	font-weight: 600;
	font-size: 25px;
	line-height: 30px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 23px;
		line-height: 27px;
	}

	@media ${device.laptopL} {
		font-size: 21px;
		line-height: 1.5;
	}
`;
export const Lable = styled.h3`
	font-weight: 600;
	font-size: 23px;
	line-height: 28px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 21px;
		line-height: 26px;
	}

	@media ${device.laptopL} {
		font-size: 19px;
		line-height: 1.5;
	}
`;
export const Text = styled.p`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 18px;
		line-height: 22px;
	}

	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 1.5;
	}
`;
export const ContactLink = styled.a`
	text-decoration: none;
	font-weight: 400;
	font-size: 20px;
	line-height: 21px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 18px;
		line-height: 19px;
	}

	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 1.5;
	}
`;
