import styled from 'styled-components';
import theme from '../../constants/theme';
import { Link } from 'react-router-dom';
import { device } from '../../constants/size';

export const LinkButton = styled(Link)``;

export const StyledButtonForm = styled.button`
	width: 295px;
	height: 66px;
	background: ${theme.color.bronze};
	border-radius: 50px;
	font-weight: 600;
	font-size: 19px;
	line-height: 23px;
	color: ${theme.color.black};
	border: none;
	box-sizing: border-box;
	
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		transform: scale(1.01);
	}

	@media ${device.tablet} {
		font-size: 17px;
		line-height: 21px;
		width: 260px;
		height: 60px;
	}
	
	@media ${device.mobileL} {
		font-size: 15px;
		line-height: 19px;
		width: 240px;
		height: 60px;
	}

	@media ${device.mobileM} {
		font-size: 14px;
		line-height: 18px;
		width: 210px;
		height: 50px;
	}

	@media ${device.mobileS} {
		font-size: 13px;
		line-height: 17px;
		width: 200px;
		height: 40px;
	}
`;
