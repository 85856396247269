import styled from 'styled-components';
import theme from '../../constants/theme';
import { device } from '../../constants/size';

export const ContantWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;

	@media (max-width: 1500px) {
		gap: 30px;
		align-items: center;
	}
`;

export const PartnersWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 50%;

	@media (max-width: 1500px) {
		flex-direction: column;
		margin: 0 0 50px 0;
		align-items: center;
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: 0 0 20px 0;
	flex: 50%;
	@media (max-width: 1500px) {
		margin: 0 0 50px 0;
		align-items: center;
	}
`;
export const Title = styled.h1`
	font-weight: 800;
	font-size: 35px;
	line-height: 43px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 32px;
		line-height: 40px;
	}

	@media ${device.laptopL} {
		font-size: 30px;
		line-height: 41px;
	}

	@media ${device.tablet} {
		font-size: 28px;
		line-height: 1.5;
	}
	@media ${device.mobileL} {
		font-size: 26px;
	}
	@media ${device.mobileM} {
		font-size: 24px;
	}
	@media ${device.mobileS} {
		font-size: 22px;
	}
`;

export const SubTitle = styled.h2`
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;
	color: ${theme.color.bronze};

	@media ${device.desktop} {
		font-size: 23px;
		line-height: 28px;
	}

	@media ${device.laptopL} {
		font-size: 21px;
		line-height: 26x;
	}

	@media ${device.tablet} {
		line-height: 1.5;
	}
`;

export const Lable = styled.h2`
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;
	color: ${theme.color.white};

	@media ${device.desktop} {
		font-size: 23px;
		line-height: 28px;
	}

	@media ${device.laptopL} {
		font-size: 21px;
		line-height: 26x;
	}

	@media ${device.tablet} {
		font-size: 19px;
		line-height: 1.5;
	}
	@media ${device.mobileL} {
		font-size: 18px;
	}

	@media ${device.mobileM} {
		font-size: 16px;
	}
`;

export const LableWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
	}
`;

export const Image = styled.img`
	width: 150px;
	height: 100px;
	margin-top: 10px;
`;
