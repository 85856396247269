export const list = [
	{
		id: 1,
		title: 'Кабельная продукция',
		text: [
			{ item: 'ВВГ' },
			{ item: 'ВВГнг (А) (Кабель силовой медный с ПВХ изоляцией, не распространяющий горение)' },
			{ item: 'ВВГHГ(А)-FRLS (Кабель огнестойкий медный, не распростр. горение, с низким дымо-и газовыделением)' },
			{ item: 'ВВГHГ(А)-LS (Кабель силовой медный с ПВХ изоляцией, с низким дымо-и газовыделением)' },
			{ item: 'ВВГЭНГ (А)-LS (Kабель силовой медный, экраниров., с ПВХ изоляцией, с низким дымо-и газовыделением)' },
			{ item: ' -'},
			{ item: 'ВБШв' },
			{ item: 'ВБШв, нг(А) (Кабель силовой медный с ПВХ изоляцией, бронированный) ВБШвнг(А)-LS (Кабель силовой медный с ПВХ изол. бронированный, с низким дымо- и газовыделением)' },
			{ item: '-' },
			{ item: 'КВВГ' },
			{ item: 'нг (А), нг (А)-LS, Hг (A)-FRLS,' },
			{ item: 'КВВГЭ, нг (А), нг(А)-LS, Hг (А)-FRLSLTX, нг (A)- LSLTX, ' },
			{ item: 'KВБ6ШВ, нг(А),' },
			{ item: 'АКВВГ (Кабель контрольный)' },
			{ item: '-' },
			{ item: 'КГ' },
			{ item: 'КГтп, КГ-ХЛ, КГТП-ХЛ, КГН, ' },
			{ item: 'Рпш (Кабель в резиновой изоляции) КГВВ, КГВВНГ(А), КГВЭВНГ (А), КГВВнг(А)-LS, ' },
			{ item: 'КГВЭВнг(А)-LS (Kабель гибкий с ПВХ изоляцией)' },
			{ item: '-' },
			{ item: 'КГВВ' },
			{ item: 'КГВВНГ(А), КГВЭВНГ (А), КГВВнг(А)-LS,' },
			{ item: 'КГВЭВнг(А)-LS (Kабель гибкий с ПВХ изоляцией)' },
			{ item: '-' },
			{ item: 'КППГ' },
			{ item: 'КППГНГ (А)-FRHF (Кабели контрольные, не распр. горен.с изол. и обол.из полим. комп.,не содер. галог)' },
			{ item: 'КППГНГ(А)-НF,' },
			{ item: 'КппГЭнг(А)-HF (Кабель контрольный, изол. и оболочкой из полимерных композиций, не содер. галогенов)' },
			{ item: '-' },
			{ item: 'МКШ' },
			{ item: 'МКЭШ, МкЭШвнг(А), МКЭШВНГ(А)-LS, MГШШВ, НВ,' },
			{ item: 'МЛТП (Провод монтажный)' },
			{ item: '-' },
			{ item: 'ПВС' },
			{ item: 'ПВСHГ(А)-LS, ШВВП (Провода бытовые и шнуры соединительные) ' },
			{ item: 'ПНСВ (Провод прогревочный)' },
			{ item: '-' },
			{ item: 'ППГ' },
			{ item: 'ППГНГ(А)-FRHF (Кабели огнест. силов., не распр. горен.с изол. и обол.из полим. комп., не содер. галог) ' },
			{ item: 'ППГНГ(А)-НF (Кабель силовой медный с изол. и оболочкой композиций, не содер. галогенов) полимерных' },
			{ item: '-' },
			{ item: 'Пув' },
			{ item: 'ПуГВ, ПуВнг (А)-LS,' },
			{ item: 'ПуГВнг (А)-LS (Провода установочные)' },
			{ item: '-' },
			{ item: 'Сип' },
			{ item: '(Самонесущий изолированный провод)' },
			{ item: '-' },
			{ item: 'ТППЭП' },
			{ item: 'ЭПЗ, ЭПБШП, ТСВ,' },
			{ item: 'ТРП (Телефонные кабели и провода),' },
		],
	},
	{
		id: 2,
		title: 'Кабеленесущие системы',
		text: [
			{ item: 'Аксессуары для кабельных лотков универсальные ' },
			{ item: 'Аксессуары и крепёж труб ' },
			{ item: 'Кабель-каналы монтажные' },
			{ item: 'Кабель-каналы напольные ' },
			{ item: 'Кабель-каналы настенные ' },
			{ item: 'Кабель-каналы перфорированные ' },
			{ item: 'Кабель-каналы плинтусные' },
			{ item: 'Кабель лоток лестничный' },
			{ item: 'Кабельный лоток проволочный' },
		],
	},
	{
		id: 3,
		title: 'Измерительные приборы',
		text: [
			{ item: 'Приборы измерения ' },
			{ item: 'Стабилизаторы напряжения' },
			{ item: 'Электрические счётчики' },
			{ item: 'Трансформаторы' },
		],
	},
	{
		id: 4,
		title: 'Светотехника',
		text: [
			{ item: 'Аварийное освещение' },
			{ item: 'Вспомогательное оборудование для светотехники ' },
			{ item: 'Опоры освещения ' },
			{ item: 'Ламподержатели ' },
			{ item: 'Прожекторы и светильники направленного света ' },
			{ item: 'Устройства питания для световых приборов ' },
			{ item: 'Светильники ( освещение дорог, улиц )' },
			{ item: 'Светильники настенно-потолочные ' },
			{ item: 'Трансформаторы для низковольтных систем освещения' },
		],
	},
	{
		id: 5,
		title: 'Низковольтное оборудование',
		text: [
			{ item: 'Автоматические выключатели дифференциального тока ' },
			{ item: 'Автоматические выключатели модульные ' },
			{ item: 'Автоматические ю выключатели стационарные ' },
			{ item: 'Автоматы защита двигателя ' },
			{ item: 'Выключатели нагрузки ' },
			{ item: 'Измерительные приборы для установки в щит ' },
			{ item: 'Компоненты светосигнальной арматуры ' },
		],
	},
	{
		id: 6,
		title: 'Звонки дверные, домофонные системы',
		text: [
			{ item: 'Звонки, кнопки ' },
			{ item: 'Видеодомофоны' },
			{ item: 'Камеры наблюдения ' },
		],
	},
	{
		id: 7,
		title: 'Электрические изделия',
		text: [
			{ item: 'Вилки кабельные и приборные ( блоки комбинирования )' },
			{ item: 'Выключатели ( с дистанционным управлением )' },
			{ item: 'Выключатели/переключатели ' },
			{ item: 'Рамки, суппорты, адаптеры ' },
			{ item: 'Розетки силовые ' },
			{ item: 'Удлинители, розеточные блоки, разветвители, переходники ' },
			{ item: 'Реле' },
			{ item: 'Электроустановочные устройства различного назначения ' },
		],
	},
	{
		id: 8,
		title: 'Системы обогрева, вентиляции, климатотехника',
		text: [
			{ item: 'Водонагреватели' },
			{ item: 'Водоснабжение' },
			{ item: 'Обогревательные приборы' },
			{ item: 'Системы кабельного обогрева ' },
			{ item: 'Системы отопления жилых помещений' },
		],
	},
	{
		id: 9,
		title: 'Пожарные/охранные системы сигнализации',
		text: [
			{ item: 'Пожарная сигнализация, система оповещения' },
			{ item: 'Охранная сигнализация, система оповещения' },
		],
	},
];

// text: [
// 	{item: ''},
// ]
