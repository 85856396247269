import styled from 'styled-components';
import { BACKGROUND, FORM_BACKGROUND } from './images';
import { device } from './size';
import theme from './theme';

export const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
`;

export const FormWrapperMain = styled.div`
	width: 100%;
	height: 100vh;
	background: center no-repeat url(${FORM_BACKGROUND})
		${theme.color.grayBackground};
	@media ${device.dekstopS} {
		background: ${theme.color.grayBackground};
	}
`;
